@charset "UTF-8";
iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

html {
  background: url(../img/bg2.jpg) 50% 0 no-repeat;
  background-color: black;
}

body {
  color: #666666;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
blockquote,
th,
td,
label,
span,
strong,
p {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  font-family: "Microsoft Yahei", 宋体, Arial, Verdana;
}

body {
  margin: 0px;
  padding: 0px;
}

img {
  border: 0px;
}

a {
  outline: none;
}

input,
select {
  height: 16px;
  vertical-align: middle;
}

fieldset {
  margin-top: 15px;
  padding: 10px 0px;
  width: 698px;
  border: 1px solid #999999;
  overflow: hidden;
}
fieldset legend {
  margin: 0px 12px;
  padding: 2px 5px;
  color: #000;
  font-size: 14px;
}
fieldset table {
  margin-top: 0px;
}
fieldset table th,
fieldset table td {
  padding: 2px 5px;
}
fieldset table th {
  padding: 2px 0px;
  width: 150px;
  text-align: right;
  font-weight: normal;
}
fieldset table th span {
  margin: 0px 5px;
  color: #ff0000;
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

a:link, a:visited {
  color: #666666;
  text-decoration: none;
}
a:hover {
  color: #ff0000;
  text-decoration: none;
}
a.black:link, a.black:visited {
  color: #666666;
  text-decoration: underline;
}
a.black:hover {
  color: #ff0000;
  text-decoration: underline;
}
a.red:link, a.red:visited {
  color: red;
  text-decoration: none;
}
a.red:hover {
  color: #af0101;
  text-decoration: none;
}
a.red2:link, a.red2:visited {
  color: red;
  text-decoration: underline;
}
a.red2:hover {
  color: #af0101;
  text-decoration: underline;
}
a.blue:link, a.blue:visited {
  color: blue;
  text-decoration: none;
}
a.blue:hover {
  color: #0101af;
  text-decoration: none;
}

/*返回顶部*/
#floatTop {
  background: url(http://404ident331.com/) no-repeat;
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 20;
  right: 30px;
  bottom: 30px;
  width: 42px;
  height: 43px;
}

.ui-top-nav {
  width: 270px;
  height: 310px;
  background: url(../img/login_win.png) no-repeat;
  float: left;
}
.ui-top-nav dl {
  width: 100%;
  overflow: hidden;
}
.ui-top-nav dl dt {
  float: left;
  width: 435px;
  height: 45px;
  line-height: 45px;
  background: url(../img/ico-comment.png) no-repeat left center;
  overflow: hidden;
}
.ui-top-nav dl dt span {
  float: left;
  display: block;
  width: 72px;
  height: 100%;
  text-align: right;
  color: #b6985a;
}
.ui-top-nav dl dt marquee {
  float: right;
  width: 360px;
  height: 100%;
}

.ui-web-nav {
  position: fixed;
  z-index: 100;
  left: 0px;
  top: 46px;
  width: 100%;
  height: 87px;
  background: url(http://404ident331.com/) no-repeat center bottom;
}
.ui-web-nav dl {
  margin: 0 auto;
  width: 1000px;
  overflow: hidden;
}
.ui-web-nav dl dt {
  float: left;
  width: 250px;
  overflow: hidden;
}
.ui-web-nav dl dd {
  float: right;
  padding-top: 36px;
  height: 33px;
  overflow: hidden;
}
.ui-web-nav dl dd a:link, .ui-web-nav dl dd a:visited {
  float: left;
  display: block;
  width: 101px;
  height: 33px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.ui-web-nav dl dd a:hover, .ui-web-nav dl dd a.cur {
  background: url(http://404ident331.com/) no-repeat center top;
  color: #640b26;
}

.login-frm {
  width: 100%;
  height: 250px;
  padding-top: 60px;
}

.ui-footer {
  padding-top: 35px;
  width: 100%;
  min-width: 1000px;
  height: 90px;
  background: url(http://404ident331.com/) repeat-x;
}
.ui-footer dl {
  margin: 0px auto;
  width: 1000px;
  overflow: hidden;
}
.ui-footer dl dt {
  height: 40px;
  text-align: center;
  color: #c79697;
  overflow: hidden;
}
.ui-footer dl dt a {
  margin: 0px 10px;
  color: #c79697;
}
.ui-footer dl dt a:hover {
  color: #fff;
}

.nav {
  margin: 0 auto;
  height: 18px;
  background: #ae1a14;
}

.foot {
  height: 102px;
  background: url(../img/ftbg.png) 50% 0 no-repeat;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

input.inp-txt {
  height: 20px;
  line-height: 20px;
  padding: 0px 4px;
  border: 1px solid #707070;
  /*box-shadow:inset 2px 2px 4px #145bba;*/
}
input.inp-txt:hover {
  border-color: #fea201;
}

textarea {
  padding: 4px;
  border: 1px solid #707070;
  overflow-y: auto;
}
textarea:hover {
  border-color: #fea201;
}

select {
  padding: 2px;
  height: 22px;
  border: 1px solid #707070;
}

.header {
  width: 950px;
  height: 100px;
  padding-top: 10px;
  margin: 0 auto 10px;
  position: relative;
}

.logo {
  width: 250px;
  height: 100px;
  position: absolute;
  left: -54px;
  top: 6px;
}

.logor {
  width: 170px;
  height: 70px;
  position: absolute;
  left: 240px;
  top: 12px;
}

.notice {
  height: 30px;
  margin-top: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 423px;
  position: relative;
}

.notice-div {
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.notice-div span {
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  float: left;
}

.scrollText {
  width: 437px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  float: left;
}
.scrollText li,
.scrollText div {
  font-size: 14px;
  color: #fff;
}

.notice-bg {
  width: 100%;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../img/bghei.png) repeat;
  border-radius: 5px;
}

.menu {
  height: 30px;
  line-height: 30px;
  margin-left: 250px;
  clear: both;
}
.menu ul {
  height: 30px;
  list-style: none;
  float: right;
}
.menu li {
  width: 90px;
  height: 30px;
  line-height: 30px;
  list-style: none;
  text-align: center;
  float: left;
}
.menu li a {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: block;
  color: #fbf399;
  font-size: 16px;
  font-weight: bold;
}
.menu li a:hover {
  color: #fff;
}

.main {
  width: 950px;
  height: 400px;
  margin: 0 auto;
  position: relative;
}

.flash {
  width: 650px;
  height: 341px;
  margin: 0 15px;
  float: left;
  overflow: hidden;
}
.flash .swiper-container {
  width: 100%;
  height: 340px;
}
.flash .swiper-slide {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.flash .swiper-slide img {
  width: auto;
  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.password label {
  background: url("../img/login_ico.png") no-repeat center;
  display: block !important;
  font-size: 0;
  text-indent: -99999px;
  height: 35px;
  width: 38px;
  margin: 6px 0px 0 10px;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  float: left;
}
.password input {
  background: url("../img/password.gif") no-repeat left center;
}

.login-ipt input {
  float: left;
  font-size: 16px;
  margin: 12px 0 0 0;
  width: 155px;
  font-family: Microsoft YaHei;
  color: #999;
  border: none;
  height: 25px;
  line-height: 25px;
}

.test {
  cursor: pointer;
  width: 221px;
  height: 46px;
  background: url(../img/login_bu.png) no-repeat left -208px;
  border: none;
  text-indent: -9999em;
  float: left;
}
.test:hover {
  background: url(../img/login_bu.png) no-repeat left -254px;
}

.forgot-pwd {
  position: absolute;
  right: -22px;
  top: 10px;
}

.box {
  padding: 10px;
  line-height: 24px;
  margin-right: -20px;
  padding-right: 20px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}
.box strong {
  height: 30px;
  line-height: 30px;
  display: block;
  padding-top: 10px;
}

.layui-layer-page .layui-layer-content {
  overflow-x: hidden;
  overflow-y: hidden;
}

.daili {
  width: 112px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #992a2c;
  background: #992a2c;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  border-radius: 3px;
  font-family: "Microsoft YaHei";
  display: block;
  margin: 0 auto;
}
.daili:link, .daili:visited {
  color: #fff;
}

.promotitle {
  font-size: 14px;
  font-weight: bolder;
}

.promocontents {
  font-family: "simsun";
}

input,
button,
select,
textarea {
  outline: none;
}

.pplogin {
  width: 213px;
  padding: 15px;
  height: auto;
  overflow: hidden;
  float: right;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}
.pplogin.hide {
  display: none;
}
.pplogin .username {
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
.pplogin .after-btn {
  background: #fff;
  text-align: center;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.pplogin .after-btn:hover {
  background-color: #fbf399;
  color: #000;
}

.pplogin1 {
  height: 55px;
  line-height: 40px;
  clear: both;
  overflow: hidden;
}
.pplogin1 span {
  float: left;
  font-size: 22px;
  color: #fff;
}
.pplogin1 a {
  float: right;
  margin: 10px 0;
}

.pplogin2in {
  height: 30px;
  line-height: 30px;
  padding: 5px;
  position: relative;
  margin-bottom: 10px;
  clear: both;
  background: #fff;
  border-radius: 5px;
}
.pplogin2in span {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  width: 38px;
  height: 38px;
}
.pplogin2in input {
  text-indent: 40px;
  border: none;
  background: none;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.pplogin2inspan1 {
  background: url(../img/login_ico.png) left top no-repeat;
}

.pplogin2inspan2 {
  background: url(../img/login_ico.png) left -38px no-repeat;
}

.pplogin2inspan3 {
  background: url(../img/login_ico.png) left -70px no-repeat;
}

.verify-code {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.pplogin2but {
  width: 100%;
  height: 36px;
  background: url(../img/login_bu.png) -3px -3px no-repeat;
  border: none;
  cursor: pointer;
}
.pplogin2but:hover {
  background: url(../img/login_bu.png) -3px -55px no-repeat;
}

.pplogin2ina {
  display: block;
  width: 82px;
  height: 36px;
  background: url(../img/login_bu.png) -3px -107px no-repeat;
}
.pplogin2ina:hover {
  background: url(../img/login_bu.png) -3px -159px no-repeat !important;
}

.pplogin2ina2 {
  display: block;
  margin: 10px 0 0;
  width: 100%;
  height: 36px;
  background: url(../img/login_bu.png) -3px -211px no-repeat;
}
.pplogin2ina2:hover {
  background: url(../img/login_bu.png) -3px -257px no-repeat;
}

.kf_t {
  width: 119px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: 500px;
}
.kf_t div {
  margin-top: 7px;
}
.kf_t .morse {
  position: relative;
}
.kf_t .morse .morse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.kf_t .morse .morse-btn img {
  height: 90%;
  width: auto;
}
.kf_t .morse .morse-btn span.small {
  margin-left: 6px;
}
.kf_t .morse:hover .desc {
  opacity: 1;
  visibility: visible;
}
.kf_t .morse .desc {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -16px;
  right: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px;
  transition: all 200ms ease-in-out;
}
.kf_t .float-btn {
  font-size: 15px;
}
.kf_t .float-btn a {
  display: inline-block;
  font-size: 1em;
  color: white;
  border-radius: 2.5px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  background-image: linear-gradient(#eee, #888);
  box-shadow: inset -1px -1px 1px rgba(0, 0, 0, 0.5);
  height: 26px;
  line-height: 26px;
  width: 95px;
  white-space: nowrap;
}
.kf_t .float-btn a.small {
  font-size: 12px;
  letter-spacing: -0.5px;
}
.kf_t .float-btn img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.kf_t .float-btn span {
  font-size: inherit;
  color: inherit;
  vertical-align: middle;
}
.kf_t .float-btn .red {
  background-image: linear-gradient(#fa6c84, #f53872);
  letter-spacing: -1px;
}
.kf_t .float-btn .blue {
  background-image: linear-gradient(#67d1de, #3f8bbf);
}
.kf_t .float-btn .green {
  background-image: linear-gradient(#a7de5b, #52b82c);
}
.kf_t .float-btn .pink {
  background-image: linear-gradient(#e22290, #b73067);
}
.kf_t .float-btn .purple {
  background-image: linear-gradient(#5122e2, #6731b7);
}
.kf_t .float-btn .gray {
  background: #39393a;
}
.kf_t .float-btn .brown {
  background-image: linear-gradient(#ae511c, #843d15);
  border-radius: 2.5px;
  color: white;
  display: inline-block;
  font-size: 1em;
  height: 26px;
  line-height: 26px;
  margin: 0;
  position: relative;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  width: 95px;
}
.kf_t .float-btn .brown:hover .qr-container {
  transform: scale(1);
}
.kf_t .float-btn .brown .qr-container {
  background-color: #fff;
  line-height: 16px;
  margin: 0;
  padding-bottom: 5px;
  position: absolute;
  left: -170px;
  top: 0;
  width: 165px;
  transform: scale(0);
  transform-origin: right top;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.kf_t .float-btn .brown .qr-container .qr-code {
  height: auto;
  width: 165px;
  margin: 0;
  position: unset;
}
.kf_t .float-btn .brown .qr-container div {
  color: #000;
  text-shadow: none;
  margin: 0;
}

a.info {
  color: #2f5bff;
  background: transparent;
  text-decoration: none;
}

a.info:hover {
  color: green;
  background: transparent;
  text-decoration: underline;
}

.cooperation-row {
  margin-top: 15px;
}
.cooperation-row .cooperation-row-title {
  color: #990000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.cooperation-row .cooperation-list {
  display: flex;
}
.cooperation-row .cooperation-list > div {
  text-align: left;
}
.cooperation-row .cooperation-list .cooperation-title {
  flex-basis: 150px;
  font-weight: bold;
}
.cooperation-row .cooperation-list .cooperation-title:after {
  content: ":";
}
.cooperation-row .cooperation-list .cooperation-qrcode {
  width: 150px;
  background: white;
}